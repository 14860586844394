import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import logoMenu from "@iconify-icons/ion/md-menu";
// import logoSearch from "@iconify-icons/ion/md-search";
import logoClose from "@iconify-icons/ion/md-close";
import { Icon } from "@iconify/react";
import $ from "jquery";

function searchOpen() {
  console.log("Opening...");
  let searchBox = $(".search");

  console.log(searchBox);

  searchBox.addClass("is-visible");
}

const Header = (props) => {
  const { itemCount } = useContext(CartContext);

  header();
  function header() {
    var initialScroll;
    $(window).scroll(function () {
      var scroll = $(this).scrollTop();
      if (scroll > initialScroll && initialScroll > 70) {
        // $(".header").addClass("is-hide");
      } else {
        // $(".header").removeClass("is-hide");
      }
      initialScroll = scroll;
    });
  }

  useEffect(() => {
    var topNav = $(".top-nav"),
      menuOpenIcon = $(".nav__icon-menu"),
      menuCloseIcon = $(".ion-md-close"),
      menuList = $(".main-nav"),
      searchOpenIcon = $(".nav__icon-search"),
      searchCloseIcon = $(".search__close"),
      searchBox = $(".search");

    /* =======================
        // Hide Header
        ======================= */

    /* =======================
        // Menu and Search
        ======================= */
    menuOpenIcon.click(function () {
      menuOpen();
    });

    menuCloseIcon.click(function () {
      menuClose();
    });

    searchOpenIcon.click(function () {
      searchOpen();
    });

    searchCloseIcon.click(function () {
      searchClose();
    });

    function menuOpen() {
      topNav.addClass("is-visible");
    }

    function menuClose() {
      topNav.removeClass("is-visible");
    }

    function searchOpen() {
      searchBox.addClass("is-visible");
    }

    function searchClose() {
      searchBox.removeClass("is-visible");
    }

    // =====================
    // Ajax Load More
    // =====================
    var $load_posts_button = $(".load-more-posts");

    /* =======================
        // Zoom Image
        ======================= */
    $(".page img, .post img").attr("data-action", "zoom");
    $(".page a img, .post a img").removeAttr("data-action", "zoom");

    /* =================================
        // Fade In
        ================================= */
    // if (
    //   !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   )
    // ) {

    // }

    /* ==================================
        // If the Author section is disabled
        =================================== */
    if (!$(".home section").hasClass("author")) {
      $(".home .container__inner").addClass("without-author");
    }

    /* =======================
        // Scroll Top Button
        ======================= */
    $(".top").click(function () {
      $("html, body").stop().animate({ scrollTop: 0 }, "slow", "swing");
    });
    $(window).scroll(function () {
      if ($(this).scrollTop() > $(window).height()) {
        $(".top").addClass("is-active");
      } else {
        $(".top").removeClass("is-active");
      }
    });
  }, []);

  return (
    <div>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="header__inner col col-12 col-d-10 push-d-1 col-m-12 push-m-0">
              <div className="logo">
                <Link className="logo__link" to="/">
                  Jordyn's Kitchen
                </Link>
              </div>

              <nav className="main-nav" style={{right: 0, position: "absolute"}}>
                <div className="main-nav__box">
                  <ul className="nav__list list-reset">
                    {/* <li className="nav__item">
                      <Link className="nav__link" to="/shop">
                        Shop
                      </Link>
                    </li> */}
                    <li className="nav__item">
                      <Link className="nav__link" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li className="nav__item">
                      <Link className="nav__link" to="/about">
                        About
                      </Link>
                    </li>
                    {/* <li className="nav__item">
                      <Link className="nav__link" to="/favorites">
                        Favorites
                      </Link>
                    </li> */}

                    {/* <li className="nav__item">
                      <Link className="nav__link" to="/cart">
                        Cart ({itemCount})
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </nav>

              <nav className="top-nav">
                <div className="top-nav__box">
                  <div className="nav__icon-close">
                    <Icon className="ion ion-md-close" icon={logoClose}></Icon>
                  </div>
                  <div className="nav-grid">
                    <div className="nav-grid__item">
                      <h2 className="nav-grid__title">Menu</h2>
                      <div className="mobile-nav">
                        <ul className="nav__list list-reset">
                          {window.location.pathname !== "/" && (
                            <li className="nav__item">
                              <Link className="nav__link" to="/">
                                Blog
                              </Link>
                            </li>
                          )}
                          {/* <li className="nav__item">
                            <Link className="nav__link" to="/shop">
                              Shop
                            </Link>
                          </li> */}
                          {/* <li className="nav__item">
                            <Link className="nav__link" to="/blog">
                              Blog
                            </Link>
                          </li> */}
                          <li className="nav__item">
                            <Link className="nav__link" to="/about">
                              About
                            </Link>
                          </li>
                          {/* <li className="nav__item">
                            <Link className="nav__link" to="/favorites">
                              Favorites
                            </Link>
                          </li> */}

                          {/* <li className="nav__item">
                            <Link className="nav__link" to="/cart">
                              Cart ({itemCount})
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>

              <div className="nav-buttons">
                <Icon
                  className="nav__icon nav__icon-menu"
                  icon={logoMenu}
                ></Icon>
                {/* <Icon
                  className="nav__icon nav__icon-search"
                  icon={logoSearch}
                  onClick={searchOpen}
                ></Icon> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <div className="search">
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <div className="search__box">
                <div className="search__group">
                  <div className="search__close">
                    <i className="ion ion-md-close"></i>
                  </div>
                  <label
                    htmlFor="js-search-input"
                    className="screen-reader-text"
                  >
                    Search for Blog
                  </label>
                  <input
                    type="text"
                    id="js-search-input"
                    className="search__text"
                    autoComplete="off"
                    placeholder="Type to search..."
                  ></input>
                  <ul
                    id="js-results-container"
                    className="search-results-list"
                  ></ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
