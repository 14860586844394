import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import Masonry from "react-masonry-css";
import Skeleton from "react-loading-skeleton";

import "./Blog.css";
import Image from "../../components/image/Image";

const Blog = () => {
  const [posts, setPosts] = useState(Array.from(Array(10)).map((item) => null));
  const [numberShow, setNumberShow] = useState(10);

  const loadMore = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >
      document.scrollingElement.scrollHeight - 250
    ) {
      setNumberShow(numberShow + 10);
    }
  }, [setNumberShow, numberShow]);

  useEffect(() => {
    window.addEventListener("scroll", loadMore);

    return () => {
      window.removeEventListener("scroll", loadMore);
    };
  }, [loadMore]);

  useEffect(() => {
    let requestUrl =
      process.env.NODE_ENV === "production"
        ? "https://jordynskitchen.com:3400/posts"
        : "http://localhost:3400/posts";

    fetch(requestUrl)
      .catch((err) => {
        console.error(err);
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => Promise.reject(errorInfo));
        }
        return response.json();
      })
      .then((json) => {
        setPosts(
          json.sort((a, b) =>
            Date.parse(a.date) < Date.parse(b.date) ? 1 : -1
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const breakpointColumnsObj = {
    default: 3,
    800: 2,
    600: 1,
  };

  const generatePost = (post, index) => {
    if (!post) {
      return (
        <div key={index} className="article" style={{ marginTop: -5 }}>
          <Skeleton height={442.66} />
          <div className="article__content">
            <div className="article-tags">
              <Skeleton
                style={{ margin: "0 5px 10px 0", marginRight: "7px" }}
                height={23}
                width={76}
              />
              <Skeleton
                style={{ margin: "0 5px 10px 0" }}
                height={23}
                width={76}
              />
            </div>
            <h2 className="article__title" style={{ marginTop: 0 }}>
              <Skeleton />
            </h2>
            <p>
              <Skeleton count={5} />
            </p>
          </div>
        </div>
      );
    }

    const date = Date.parse(post.date);
    const ye = new Intl.DateTimeFormat("en", {
      year: "numeric",
    }).format(date);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

    return (
      <div key={index} className="article">
        <Link
          className="article__image"
          to={`/blog/${post.title.replaceAll(" ", "-")}`}
          style={{ width: "100%" }}
        >
          <Image
            src={"/" + post.image.replaceAll('"/', "").replaceAll('"', "")}
            height={506.66}
            alt={post.title}
          />
        </Link>
        <div className="article__content">
          <div className="article-tags">
            <div className="article-tags__box">
              {post.tags
                .substring(1, post.tags.length - 1)
                .split(",")
                .map((tag, index) => {
                  return (
                    <Link
                      key={index}
                      to="/tag/DESSERT"
                      className="article__tag"
                    >
                      {tag}
                    </Link>
                  );
                })}
            </div>
          </div>
          <h2 className="article__title" style={{ marginTop: 0 }}>
            <Link to={`/blog/${post.title.replaceAll(" ", "-")}`}>
              {post.title}
            </Link>
          </h2>
          <p className="article__excerpt">
            {`${post.html
              .replace(/<\/?[^>]+(>|$)/g, "")
              .substring(0, 157)} ...`}
            These loaves are a perfect fall treat especially with this
            buttercream frosting! I’m going to share the frosting recipe soon as
            I am still perfecting it but ...
          </p>
          <div className="article__meta">
            <div className="article__author">
              <Link
                to="/about/"
                className="article__author-image"
                style={{ backgroundImage: "url(/images/002.webp)" }}
                aria-label="'s Picture"
              ></Link>{" "}
              <Link to="/about/" className="article__author-link"></Link>
            </div>
            <span className="article__date">
              <time dateTime={post.date}>
                {mo} {da}, {ye}
              </time>
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {posts
          .slice(0, Math.min(posts.length, numberShow))
          .map((post, index) => generatePost(post, index))}
      </Masonry>
    </Layout>
  );
};

export default Blog;
