import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Loader = () => {
  let [loader, setLoader] = useState(true);

  useEffect(() => {
    const onLoad = () => {
      setLoader(false);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    };

    window.addEventListener("load", onLoad);

    return () => {
      window.removeEventListener("load", onLoad);
    };
  }, [setLoader]);

  ReactGA.initialize("UA-169253780-1");

  const location = useLocation();

  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <>
      <div
        className="loader"
        style={{
          opacity: loader ? "1.0" : "0.0",
          visibility: loader ? undefined : "hidden",
        }}
      >
        <div className="spinner">
          <div className="cube1"></div>
          <div className="cube2"></div>
        </div>
      </div>
    </>
  );
};

export default Loader;
