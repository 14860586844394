import React from "react";
import { Link } from "react-router-dom";

import { Icon } from "@iconify/react";
import logoInstagram from "@iconify-icons/ion/logo-instagram";
import logoYoutube from "@iconify-icons/ion/logo-youtube";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-d-10 push-d-1 col-m-12 push-m-0">
            <div className="footer__inner">
              <div className="contact">
                <ul className="contact__list list-reset">
                  <li className="contact__item">
                    <Link
                      className="contact__link"
                      to="https://www.instagram.com/jordynskitchen/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon={logoInstagram} />
                    </Link>
                  </li>

                  <li className="contact__item">
                    <Link
                      className="contact__link"
                      to="https://www.youtube.com/channel/UCb_g_B3LZZ8k1jSWhWtOKjw"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon={logoYoutube} />
                    </Link>
                  </li>
                  <li className="contact__item">
                    <Link
                      className="contact__link"
                      to="https://vm.tiktok.com/ojSgFt/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="Tiktok Logo"
                        style={{
                          maxWidth: "100%",
                          width: "1em",
                          height: "1em",
                        }}
                        src="/images/tiktok.svg"
                      ></img>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="copyright">
                2020 © <Link to="/">Jordyn's Kitchen</Link>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
