import React, { createContext, useEffect, useState } from "react";
export const ProductsContext = createContext();

const ProductsContextProvider = ({ children }) => {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    let requestUrl =
      process.env.NODE_ENV === "production"
        ? "https://jordynskitchen.com:3400/list-items"
        : "http://localhost:3400/list-items";

    fetch(requestUrl)
      .catch((err) => {
        console.error(err);
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => Promise.reject(errorInfo));
        }
        return response.json();
      })
      .then((json) => {
        setProducts(json);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <ProductsContext.Provider value={{ products }}>
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsContextProvider;
