import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BlogContextProvider from "./contexts/BlogContext";
import CartContextProvider from "./contexts/CartContext";
import ProductsContextProvider from "./contexts/ProductsContext";

import Blog from "./pages/blog/Blog";
import About from "./pages/about/About";
import BlogPost from "./pages/blog/BlogPost";
import Loader from "./Loader";
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
  <BrowserRouter>
    <Loader />
    <ScrollToTop />
    <ProductsContextProvider>
      <BlogContextProvider>
        <CartContextProvider>
          <Routes>
            <Route path="/" element={<Blog />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/blog" element={<Blog />}></Route>
            <Route path="/blog/:blogTitle" element={<BlogPost />}></Route>
            <Route path="*" element={<Blog />}></Route>
          </Routes>
        </CartContextProvider>
      </BlogContextProvider>
    </ProductsContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
