import React, { createContext, useEffect, useState } from "react";
export const BlogContext = createContext();

const ProductsContextProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    let requestUrl =
      process.env.NODE_ENV === "production"
        ? "https://jordynskitchen.com:3400/posts"
        : "http://localhost:3400/posts";

    fetch(requestUrl)
      .catch((err) => {
        console.error(err);
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => Promise.reject(errorInfo));
        }
        return response.json();
      })
      .then((json) => {
        setPosts(json);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <BlogContext.Provider value={{ posts }}>{children}</BlogContext.Provider>
  );
};

export default ProductsContextProvider;
